import 'keen-slider/keen-slider.min.css'

import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Arrow } from 'app/components/Common/Arrow'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { Spinner } from 'app/components/Common/Spinner'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useKeenSlider } from 'keen-slider/react'
import { uniq } from 'lodash'
import React, { memo, useEffect, useRef, useState } from 'react'

const AUTOPLAY_TIMER = 5000

export interface Props {
  cta?: ButtonProps
  description?: string
  images: ImageProps[]
  title?: string
}

export const Visual = memo(function Visual({
  cta,
  description,
  images,
  title,
}: Props) {
  if (images?.length < 1) {
    return null
  }

  const containerRef = useRef<any>()

  const [currentSlide, setCurrentSlide] = useState(0)
  const [details, setDetails] = useState<any>(null)
  const [loaded, setLoaded] = useState<boolean[]>([])
  const [nextLoaded, setNextLoaded] = useState<boolean[]>([])
  const timer = useRef<any>()

  function positionStyle(idx: any) {
    if (!details) {
      return undefined
    }

    const position = details.slides[idx].distance
    const x = (galleryRef.current?.size || window.innerWidth) * -position
    return {
      transform: `translate3d(${x}px, 0px, 0px)`,
      WebkitTransform: `translate3d(${x}px, 0px, 0px)`,
    }
  }

  const [sliderRef, galleryRef] = useKeenSlider({
    defaultAnimation: {
      duration: 1800,
    },
    drag: false,
    loop: images.length > 1 ? true : false,
    slideChanged(s) {
      setCurrentSlide(s.track.details.rel)
    },
    detailsChanged(s) {
      images.length > 1 ? setDetails(s.track.details) : null
    },
  })

  useEffect(() => {
    const newLoaded = [...loaded]
    newLoaded[currentSlide] = true
    const newNextLoaded = [...nextLoaded]
    newNextLoaded[currentSlide + 1] = true

    setLoaded(newLoaded)
    setNextLoaded(newNextLoaded)

    timer.current = setInterval(() => {
      if (galleryRef) {
        galleryRef.current?.next()
      }
    }, AUTOPLAY_TIMER)

    return () => {
      clearInterval(timer.current)
    }
  }, [currentSlide, galleryRef, sliderRef])

  return (
    <Container className="blended" ref={containerRef}>
      <Blended>
        <Wrapper>
          <Text>
            {title ? (
              <FadeInUp>
                <Title>{title}</Title>
              </FadeInUp>
            ) : null}

            {description ? (
              <FadeInUp>
                <Description
                  dangerouslySetInnerHTML={{ __html: description }}
                />
              </FadeInUp>
            ) : null}

            {cta ? (
              <FadeInUp>
                <CTA variant="invert" {...cta} />
              </FadeInUp>
            ) : null}
          </Text>
        </Wrapper>

        <Slider ref={sliderRef}>
          {uniq(images).map((item, index) => (
            <Slide key={index} className="keen-slider__slide">
              <Inner style={positionStyle(index)}>
                <Image
                  alt={loaded[index] || nextLoaded[index] ? item.alt : ''}
                  animationStart={
                    loaded[index] || nextLoaded[index] ? true : false
                  }
                  {...(loaded[index] || nextLoaded[index] ? item : null)}
                />
              </Inner>
            </Slide>
          ))}
        </Slider>

        {images.length > 1 ? (
          <Arrows row>
            <Arrow
              onClick={(e) => e.stopPropagation() || galleryRef.current?.prev()}
            />
            <Arrow
              direction="R"
              onClick={(e) => e.stopPropagation() || galleryRef.current?.next()}
            />
          </Arrows>
        ) : null}
      </Blended>
    </Container>
  )
})

const Container = styled.section`
  height: 100vh;
  max-height: -webkit-fill-available;
  overflow: hidden;
  position: relative;

  @media (max-width: 1199px) {
    height: auto;
    max-height: none;
    padding: 6.25rem 1.5rem 3.75rem;
  }
`

const Blended = styled.div`
  mix-blend-mode: difference;

  @supports (font: -apple-system-body) and (-webkit-appearance: none) {
    mix-blend-mode: luminosity;
  }

  @media (max-width: 1199px) {
    mix-blend-mode: normal;
    &:before {
      content: '';
      width: 100%;
      height: 100%;
      background: ${({ theme }) => theme.colors.variants.neutralDark1};
      opacity: 0.4;
      backface-visibility: hidden;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
    }
  }
`

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  color: ${({ theme }) => theme.colors.variants.neutralLight5};
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.colors.variants.neutralDark1};
    opacity: 0.35;
    backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 1199px) {
    position: relative;
    &:before {
      display: none;
    }
  }
`

const Text = styled.div`
  position: absolute;
  top: 50%;
  right: 1.5rem;
  left: 17.5vw;
  transform: translateY(-50%);

  @media (max-width: 1199px) {
    position: initial;
    top: auto;
    right: auto;
    left: auto;
    transform: none;
  }
`

const Title = styled.h2`
  max-width: 38.625rem;
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 3.625rem;
  font-weight: 600;
  line-height: 3.75rem;
  text-transform: uppercase;

  @media (max-width: 1199px) {
    max-width: none;
    font-size: 1.875rem;
    line-height: 1.875rem;
  }
`

const Description = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.5rem;
  line-height: 1.875rem;
  margin-top: 0.9375rem;

  @media (max-width: 1199px) {
    margin-top: 1.5625rem;
  }
`

const CTA = styled(Button)`
  margin-top: 3.75rem;

  @media (max-width: 1199px) {
    margin-top: 2.5rem;
  }
`

const Slider = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  outline: none;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
`

const Slide = styled.div`
  overflow: hidden;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
  }
`

const Inner = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition-timing-function: cubic-bezier(0.76, 0, 0.24, 1);
`

const Arrows = styled(FlexBox)`
  position: absolute;
  right: 10.833vw;
  bottom: 9.75rem;
  z-index: 1;

  > div {
    &:last-of-type {
      margin-left: 0.75rem;
    }
  }

  @media (max-width: 1199px) {
    margin-top: 3.4375rem;
    position: relative;
    right: auto;
    bottom: auto;

    > div {
      &:last-of-type {
        margin-left: 0.625rem;
      }
    }
  }
`
